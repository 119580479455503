
import GwSection from '~/patterns/organisms/section/section.vue';

export default {
    components: {
        GwSection
    },

    props: {
        htmlEmbed: {
            type: String,
            required: true,
            default: null
        }
    }
};
